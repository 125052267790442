import React, { useEffect, useRef, useState, useMemo } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import Box from '@material-ui/core/Box';
import './button.css';

const ReportsDataTable = ({ exportFunc }) => {
  const tableRef = useRef(null);
  let endpoint = window.location.href;
  const [render, setRender] = useState(false);
  const minDateRef = useRef(getYesterdayDate());
  const maxDateRef = useRef(getTodayDate());

  function getYesterdayDate() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  }

  function getTodayDate() {
    return new Date().toISOString().split('T')[0];
  }

  const columns = useMemo(() => [
    {
      title: 'Email Address',
      data: 'email_address',
      defaultContent: '<i>No value</i>',
    },
    { title: 'Pixel ID', data: 'uuid', defaultContent: '-' },
    {
      title: 'List Name',
      data: 'list_name',
      defaultContent: '<i>No value</i>',
    },
    {
      title: 'Integration',
      data: 'integration_name',
      defaultContent: '<i>No value</i>',
    },
    { title: 'Status Code', data: 'status_code', defaultContent: '-' },
    {
      title: 'Response Text',
      data: 'response_text',
      defaultContent: '<i>No value</i>',
    },
    {
      title: 'Date Created',
      data: 'date_created',
      defaultContent: '<i>No value</i>',
      render: d => formatDate(d),
    },
    {
      title: 'Date Triggered',
      data: 'date_sent',
      defaultContent: '<i>No value</i>',
      render: d => formatDate(d),
    },
  ]);

  const fetchData = async params => {
    params['dateStart'] = minDateRef.current?.value;
    params['dateEnd'] = maxDateRef.current?.value;

    console.log('DATES: ', params);

    const response = await axios.post(
      `/reports/outgoingNotifications/?datatables=yes`,
      {
        ...params,
      },
    );
    console.log('FETCH RESPONSE: ', response);

    return response;
  };

  useEffect(() => {
    const initializeDataTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          let min = minDateRef.current.value;
          let max = maxDateRef.current.value;
          let date = new Date(data[9]);

          if (
            (min === '' && max === '') ||
            (min === '' && date <= new Date(max)) ||
            (new Date(min) <= date && max === '') ||
            (new Date(min) <= date && date <= new Date(max))
          ) {
            return true;
          }
          return false;
        });

        $(tableRef.current).DataTable({
          serverSide: true,
          processing: true,
          columns: columns,
          dom: '<"d-flex justify-content-between"<"date-range-inputs">fBl>rtip',
          responsive: true,
          scrollX: true,
          order: [[7, 'desc']],
          ajax: (data, callback, settings) => {
            console.log('DATA: ', data);
            fetchData(data)
              .then(result => {
                console.log('RESULT: ', result);
                callback({
                  draw: data.draw,
                  recordsTotal: result.recordsTotal || result.length,
                  recordsFiltered: result.recordsFiltered || result.length,
                  data: result.data,
                });
              })
              .catch(error => {
                console.error('Error fetching data: ', error);
                callback({
                  draw: data.draw,
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: [],
                });
              });
          },
          buttons: [
            {
              extend: 'csv',
              text: 'Export',
              action: function (e, dt, node, config) {
                fetchAllDataAndExport();
              },
            },
          ],
          language: {
            infoFiltered: '',
          },
          initComplete: function (settings, json) {
            const api = this.api();
            applyCustomStyles(api);

            $('.date-range-inputs').html(
              `<label class="date-rage-input">From: <input type="date" id="min" name="min" value="${minDateRef.current}"></label> &nbsp; ` +
                `<label class="date-rage-input">To: <input type="date" id="max" name="max" value="${maxDateRef.current}"></label>`,
            );

            minDateRef.current = document.getElementById('min');
            maxDateRef.current = document.getElementById('max');

            $('#min, #max').on('change', function () {
              api.draw();
            });
          },
          drawCallback: function () {
            const api = this.api();
            applyCustomStyles(api);
          },
        });
      }
    };

    initializeDataTable();

    window.addEventListener('resize', initializeDataTable);

    return () => {
      window.removeEventListener('resize', initializeDataTable);
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [columns, endpoint, render]);

  async function fetchAllDataAndExport() {
    const table = $(tableRef.current).DataTable();
    const params = table.ajax.params();

    try {
      const response = await axios.post(
        '/reports/outgoingNotifications/?datatables=yes&export=yes',
        params,
      );
      if (response.data && response.data.length > 0) {
        const csvData = convertToCSV(response.data);
        downloadCSV(csvData, `export_${formatDate()}.csv`);
        setRender(!render);
      }
    } catch (error) {
      console.error('Error fetching data for export:', error);
    }
  }

  function formatDate(d = null) {
    if (d) {
      const date = new Date(d);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      today = mm + '-' + dd + '-' + yyyy;
      return today;
    }
  }

  function convertToCSV(data) {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map(header => {
        const val = row[header];
        return typeof val === 'string' ? `"${val.replace(/"/g, '""')}"` : val;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  function downloadCSV(csvData, filename) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function applyCustomStyles(api) {
    $(api.table().container()).find('.makeStyles-paper-35').css({
      'max-width': '1200px',
    });
    $(api.table().container())
      .find('label, input, select, th, td, .dt-info')
      .css({
        'font-size': '14px',
        'font-weight': 'bold',
      });
    $(api.table().container()).find('label').css({
      padding: '5px',
    });
    $(api.table().container()).find('input, select').css({
      margin: '10px',
    });
    $(api.table().container()).find('.date-rage-input').css({
      padding: '8px',
    });
    $(api.table().container()).find('thead tr th').css({
      'padding-left': '10px',
      'text-align': 'left',
    });
    $(api.table().container()).find('.d-flex .justify-content-between').css({
      display: 'flex',
      'flex-direction': 'space-around',
    });
    $(api.table().container()).find('.dt-info').css({
      'padding-left': '10px',
    });
    $(api.table().container()).find('tbody tr td').css({
      padding: '5px 10px',
    });
    $(api.table().container()).find('.buttons-html5, .buttons-print').css({
      'background-color': '#4CAF50',
      'border-width': '0',
      'border-radius': '5px',
      color: 'white',
    });

    // Apply custom styles for pagination controls
    $(api.table().container()).find('ul.pagination').css({
      display: 'flex', // Use flexbox to align items horizontally
      justifyContent: 'center', // Center align pagination controls
      padding: '0', // Remove default padding
      marginTop: '10px', // Add some spacing above the pagination controls
    });

    $(api.table().container()).find('.date-range-inputs').css({
      'font-size': '14px',
      'font-weight': 'bold',
    });

    $(api.table().container()).find('ul.pagination li').css({
      margin: '0 2px', // Add spacing between pagination items
    });

    $(api.table().container()).find('ul.pagination li a').css({
      display: 'inline-block', // Ensure links are inline
      padding: '5px 10px', // Add padding for clickable area
      border: '1px solid #ddd', // Border for pagination items
      borderRadius: '5px', // Rounded corners for pagination items
      textDecoration: 'none', // Remove underline from links
      color: '#333', // Text color
    });

    $(api.table().container()).find('ul.pagination li.active a').css({
      backgroundColor: '#E00001', // Highlight background color for the active page
      color: 'white', // Text color for active page
    });

    $(api.table().container()).find('ul.pagination li a:hover').css({
      backgroundColor: '#f1f1f1', // Background color on hover
      color: '#333', // Text color on hover
    });
  }

  return (
    <>
      <Box>
        <table
          ref={tableRef}
          className="display"
          style={{ width: '100%' }}
        ></table>
      </Box>
    </>
  );
};

export default ReportsDataTable;
