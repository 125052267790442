import { useState, useRef, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator_modern.min.css';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useMutation } from 'react-query';
import FiltersBox from './FiltersBox';
import CommissionsTable from './CommissionsTable';
import PageHeader from '../../components/PageHeader';
import Banner from '../../components/Banner';
import IncomingNotificationsTable from './IncomingNotificationsTable';
import OutgoingNotificationsTable from './OutgoingNotificationsTable';
import EmailActivitiesTable from './EmailActivities';
import ReportsDataTable from './ReportsDataTable';
import ReportsAll from '../ReportsAll';
import { appPermissions, useAccess } from '../../hooks/useAccess';
import axios from 'axios';
import qs from 'qs';
import { API_BASE_URL } from '../../utils/apis/adminTemplates';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import { useSnackbar } from 'notistack';

const displayTypes = {
  myPixels: 'MY_PIXEL_FIRES',
  myTriggers: 'MY_TRIGGERS',
  myExports: 'MY_EXPORTS',
  myEmailTemplates: 'MY_EMAIL_TEMPLATES',
  //commissions: 'COMMISSIONS',
};

const Reports = () => {
  const [displayType, setDisplayType] = useState(displayTypes.myPixels);
  const partner = useCurrentPartner();
  const [filters, setFilters] = useState({
    date: 'today',
    name: '',
    pixelOwner: 'all',
    pixelStatus: '',
    emailTemplates: '',
  });
  const tableRef = useRef();
  const classes = useStyles();
  const history = useHistory();
  const { hasPermission } = useAccess();
  const { enqueueSnackbar } = useSnackbar();

  var emailHeader;
  var emailField;

  if (hasPermission(appPermissions.hashAccess)) {
    emailHeader = 'Email Hash';
    emailField = 'email_hash';
  } else {
    emailHeader = 'Email Address';
    emailField = 'email_address';
  }

  useEffect(() => {
    const tabId = window.location.hash && window.location.hash.substr(1);

    if (tabId && displayTypes[tabId]) {
      window.location.hash = '';
      setDisplayType(displayTypes[tabId]);
    }
    if (window.location.search.includes('display')) {
      setDisplayType(displayTypes.myExports);
    }
  }, []);

  const handleDisplayTypeChange = (e, tab) => {
    setDisplayType(tab);
    setFilters({ date: 'today', name: '', pixelOwner: 'all' });
  };

  const { mutateAsync: exportSuccess } = useMutation(() => {}, {
    onSuccess: () => {
      enqueueSnackbar('Compiling document. You are being redirected.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    },
    onError: error => {
      enqueueSnackbar('Sorry! Something went wrong. Please try again.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  //a generic function that calls after getting filename in response of export report apis
  const downLoadCsv = (type, response) => {
    axios
      .get(`reports/downloadSignUrl?fileName=${response.filename}`, {})
      .then(signedURLResponse => {
        if (signedURLResponse && signedURLResponse?.downloadURL) {
          axios
            .create()
            .get(`${signedURLResponse.downloadURL}`, {
              responseType: 'blob',
            })
            .then(fileResponse => {
              let dataType = fileResponse?.data?.type;
              let binaryData = [];
              binaryData.push(fileResponse?.data);
              let downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(
                new Blob(binaryData, { type: dataType }),
              );

              if (type === 'incoming') {
                downloadLink.setAttribute('download', 'my-pixel-fires.csv');
              } else if (type === 'outgoing') {
                downloadLink.setAttribute('download', `'my-triggers.csv'`);
              } else if (type === 'emails') {
                downloadLink.setAttribute('download', 'my-email_records.csv');
              }
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            });
        }
      });
  };

  const exportData = (type, filter) => {
    const query = {
      take: 100,
      offset: 0,
      filterDate: filter.date,
      filterName: filter.name,
      fDate: filter.from,
      tDate: filter.to,
    };

    if (type === displayTypes.myPixels) {
      let pixelStatusStr = 'Status: All';
      query['filterPixelStatus'] = filter.pixelStatus;
      if (filter.pixelStatus === '1') pixelStatusStr = 'Status: Active';
      if (filter.pixelStatus === '2') pixelStatusStr = 'Status: Deleted';

      query['pixelStatusOwner'] = pixelStatusStr;

      axios
        .get(`/reports/incomingNotifications/csv?${qs.stringify(query)}`, {})
        .then(response => {
          if (response && response?.data) {
            displaySuccess();
            setTimeout(
              () => history.push(`/reports/${response.data[0].id}`),
              2500,
            );
          }
        });
    } else if (type === displayTypes.myTriggers) {
      query['filterPixelOwner'] = filter.pixelOwner;
      query['pixelStatusOwner'] = `Owner: ${filter.pixelOwner}`;

      axios
        .get(`/reports/outgoingNotifications/csv?${qs.stringify(query)}`, {})
        .then(response => {
          if (response && response?.data) {
            displaySuccess();
            setTimeout(
              () => history.push(`/reports/${response.data[0].id}`),
              2500,
            );
            // downLoadCsv('outgoing', response);
          }
        });
    } else if (type === displayTypes.myExports) {
      axios
        .get(`/reports/outgoingNotifications/csv?${qs.stringify(query)}`, {})
        .then(response => {
          if (response && response?.data) {
            displaySuccess();
            setTimeout(
              () => history.push(`/reports/${response.data[0].id}`),
              2500,
            );
            // downLoadCsv('outgoing', response);
          }
        });
    } else if (type === displayTypes.myEmailTemplates) {
      query['filterEmailTemplates'] = filter.emailTemplates;
      axios
        .get(
          `${API_BASE_URL}/partners/${
            partner.id
          }/email-templates?${qs.stringify(query)}`,
          {},
        )
        .then(response => {
          if (response && response?.filename) {
            downLoadCsv('emails', response);
          }
        });
    }
    //tableRef.current.exportData();
  };

  const onFiltersSet = filters => {
    setFilters(filters);
    tableRef.current.resetData();
  };

  const displaySuccess = async () => {
    await exportSuccess();
  };

  const renderErrorMessage = errorMessage => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingY={7}
    >
      <Typography color="textSecondary">{errorMessage}</Typography>
    </Box>
  );

  return (
    <>
      <Banner
        url="https://support.inboxmailers.com/how-to-navigate-reporting/"
        text="Here’s a quick walk-through video:"
      />
      <PageHeader title="Reports" />
      <Paper className={classes.paper}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs value={displayType} onChange={handleDisplayTypeChange}>
            <Tab label="My Pixel Fires" value={displayTypes.myPixels} />
            <Tab label="My Triggers" value={displayTypes.myTriggers} />
            <Tab label="My Exports" value={displayTypes.myExports} />
            {/* <Tab label="My Creatives" value={displayTypes.myEmailTemplates} /> */}
          </Tabs>
        </Box>
        {displayType === displayTypes.myPixels && (
          <>
            <FiltersBox
              onFiltersSet={onFiltersSet}
              displayType={displayType}
              exportFunc={exportData}
            />
            <IncomingNotificationsTable
              rowsPerLoad={100}
              ref={tableRef}
              dateFilter={filters.date}
              incFromDate={filters.from}
              incToDate={filters.to}
              nameFilter={filters.name}
              pixelStatusFilter={filters.pixelStatus}
              emlHeader={emailHeader}
              emlField={emailField}
            />
          </>
        )}
        {displayType === displayTypes.myTriggers && (
          <>
            <ReportsDataTable exportFunc={exportData} />
            {/* <FiltersBox
              onFiltersSet={onFiltersSet}
              displayType={displayType}
              exportFunc={exportData}
            />
            <OutgoingNotificationsTable
              rowsPerLoad={100}
              ref={tableRef}
              dateFilter={filters.date}
              outFromDate={filters.from}
              outToDate={filters.to}
              pixelOwnerFilter={filters.pixelOwner}
              emlHeader={emailHeader}
              emlField={emailField}
            /> */}
          </>
        )}
        {displayType === displayTypes.myExports && (
          <>
            <ReportsAll />
          </>
        )}
        {/* {displayType === displayTypes.myEmailTemplates && (
          <>
            <FiltersBox
              onFiltersSet={onFiltersSet}
              displayType={displayType}
              // exportFunc={exportData}
            />
            <EmailActivitiesTable
              rowsPerLoad={100}
              ref={tableRef}
              dateFilter={filters.date}
              mailFromDate={filters.from}
              mailToDate={filters.to}
            />
          </>
        )} */}
        {displayType === displayTypes.incomingAlerts && (
          <>
            <FiltersBox
              onFiltersSet={onFiltersSet}
              displayType={displayType}
              exportFunc={exportData}
            />
            <IncomingNotificationsTable
              rowsPerLoad={100}
              ref={tableRef}
              dateFilter={filters.date}
              nameFilter={filters.name}
              pixelStatusFilter={filters.pixelStatus}
            />
          </>
        )}
        {displayType === displayTypes.outgoingAlerts && (
          <>
            {hasPermission(appPermissions.networkIn) ? (
              <>
                <FiltersBox
                  onFiltersSet={onFiltersSet}
                  displayType={displayType}
                />
                <OutgoingNotificationsTable
                  rowsPerLoad={100}
                  dateFilter={filters.date}
                  ref={tableRef}
                />
              </>
            ) : (
              renderErrorMessage(
                'You Must be Opted-In to Receive Network Triggers to View This Report. Please Contact Support for More Information.',
              )
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export { displayTypes };

export default Reports;
