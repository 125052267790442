import React from 'react';

function PartnerActivity() {
  return (
    <>
      <h1>Partners Activity</h1>
      <iframe
        src="https://metabase.smartbrandscompany.com/public/dashboard/bb2b6e15-de1d-4050-af44-7d0fb1efb3ee"
        frameborder="0"
        width="1145"
        height="600"
        allowtransparency="true"
        title="Metabase Dashboard"
      ></iframe>
    </>
  );
}

export default PartnerActivity;
