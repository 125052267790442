import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;

const itemActiveStyles = theme => ({
  color: '#fff',
  backgroundColor: theme.palette.primary.main,
  '& $itemIcon': {
    color: '#fff',
  },
  '& .MuiListItemText-root > .MuiTypography-root': {
    fontWeight: theme.typography.fontWeightMedium,
  },
});

export const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    border: 'none',
    width: drawerWidth,
    boxSizing: 'border-box',
    background: theme.palette.background.default,
    boxShadow: 'inset -1px 0 0 0 rgb(215, 219, 236)',
  },
  drawerContainer: {
    overflow: 'auto',
    '& > .MuiList-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  listHeader: {
    color: 'rgb(161, 167, 196)',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
  },
  offSiteLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(1, 2),
    boxSizing: 'border-box',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(
      ['background-color', 'color', 'fontWeight'],
      { duration: 150 },
    ),
    '&:hover': itemActiveStyles(theme),
  },
  OffSiteLinkText: {
    marginLeft: '15px',
  },
}));
