import axios from 'axios';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import TextField from '@material-ui/core/TextField';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Box from '@material-ui/core/Box';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { Tooltip } from '@material-ui/core';
import { CustomToolTipStyles } from '../../styles/theme';
import { useStyles } from './styles';

import Spinner from '../../components/Spinner';
import Builder from '../../components/Builder';
import FieldGroup from '../../components/FieldGroup';
import RouterLink from '../../components/RouterLink';
import StatusSwitch from '../../components/StatusSwitch';
import IntegrationDropdown from '../../components/IntegrationDropdown';
import TriggerMethodDropdown from '../../components/TriggerMethodDropdown';
import EntityListDropdown from '../../components/EntityListDropdown';
import useCurrentPartner from '../../hooks/useCurrentPartner';

const validationSchema = yup.object({
  name: yup.string().label('Trigger Name').max(32).required(),
  description: yup.string().label('Description').max(100),
  integration_id: yup.string().label('Integration').required(),
});

const TriggerBuilder = ({ mode, onClose, onSubmit }) => {
  const customToolTipClasses = CustomToolTipStyles();
  const classes = useStyles();
  const history = useHistory();

  const { triggerId } = useParams();
  const partner = useCurrentPartner();
  const queryClient = useQueryClient();
  const [dsc, setDsc] = useState(false);
  const [limitPerUSer, setLimitPerUser] = useState(false);

  const [triggerLevelControls, setTriggerLevelControls] = useState(
    partner.trigger_level_controls,
  );
  const [isTriggerMethod, setIsTriggerMethod] = useState(false);
  const [isEntityField, setIsEntityField] = useState(false);
  const [
    accountWideDailyTriggerLimitSwitch,
    setAccountWideDailyTriggerLimitSwitch,
  ] = useState(0);

  const [accountWideDailyTriggerLimit, setAccountWideDailyTriggerLimit] =
    useState(null);
  const [deleteContactAfterFire, setDeleteContactAfterFire] = useState(0);

  const getPartnerTrigger = async () => {
    if (!triggerId) return;
    const resp = await axios.get(`/triggers/${triggerId}`);
    console.log('RESP: ', resp);
    setAccountWideDailyTriggerLimitSwitch(resp.daily_trigger_limit_on);
    setAccountWideDailyTriggerLimit(resp.daily_trigger_limit);
    setDeleteContactAfterFire(resp.delete_contact_after_fire);
  };

  const onChangeSwitchDailyTriggerLimit = event => {
    setAccountWideDailyTriggerLimitSwitch(event.target.checked ? 1 : 0);
  };

  const onChangeSwitchDeleteAfterFire = event => {
    setDeleteContactAfterFire(event.target.checked ? 1 : 0);
  };

  const onAccountWideDailyTriggerLimitChange = event => {
    if (event.target.value < 0) {
      setAccountWideDailyTriggerLimit(0);
    } else {
      setAccountWideDailyTriggerLimit(event.target.value);
    }
  };

  const DscListener = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      if (values.daily_spend_cap === false) {
        setDsc(false);
      } else if (values.daily_spend_cap === true) {
        setDsc(true);
      }
      if (values.one_time_per_user === false) {
        setLimitPerUser(false);
      } else if (values.one_time_per_user === true) {
        setLimitPerUser(true);
      }
    }, [values]);

    return null;
  };
  const { data: trigger } = useQuery(
    ['trigger', triggerId],
    () => axios.get(`/triggers/${triggerId}`),
    {
      enabled: !!triggerId,
      onError: () => {
        history.push('/404');
      },
    },
  );

  console.log('TIGGER: ', trigger);

  // TODO: Add mutation to get campaigns by integration id
  var lmtfieldopt;

  if (trigger?.subscriber_limit_time_period === 'lifetime') {
    lmtfieldopt = [
      { value: 'lifetime', label: 'Lifetime' },
      { value: 'week', label: 'Week' },
      { value: 'day', label: 'Day' },
      { value: 'hour', label: 'Hour' },
    ];
  } else {
    lmtfieldopt = [
      { value: 'week', label: 'Week' },
      { value: 'day', label: 'Day' },
      { value: 'hour', label: 'Hour' },
    ];
  }

  const limitPerUserFields =
    trigger?.subscriber_hour_interval !== null
      ? [
          {
            name: 'subscriber_hour_interval',
            label:
              'Hours to wait until this trigger will fire again (per subscriber)',
            type: 'number',
            min: '0',
            max: '168',
          },
        ]
      : [
          {
            name: 'subscriber_limit_count',
            label: 'Limit Count',
            type: 'number',
            min: '1',
          },
          {
            name: 'subscriber_limit_time_period',
            label: 'Times Per',
            select: true,
            options: lmtfieldopt,
          },
        ];
  // TODO: Add mutation to update campaign status
  const { mutateAsync: saveTrigger } = useMutation(
    data =>
      axios.request({
        url: triggerId ? `/triggers/${triggerId}` : '/triggers',
        method: triggerId ? 'put' : 'post',
        data,
      }),
    {
      onSuccess: data => {
        queryClient.invalidateQueries('triggerList');
        triggerId && queryClient.invalidateQueries(['trigger', triggerId]);
        if (onSubmit) {
          onSubmit(data);
        } else {
          history.push('/trigger-manager');
        }

        onClose?.();
      },
    },
  );

  useEffect(() => {
    setTriggerLevelControls(partner.trigger_level_controls);
    getPartnerTrigger();
  }, [partner]);

  const submitForm = async data => {
    let parsedData = { ...data };
    console.log('*** PARSED: ', parsedData);

    if (!parsedData?.one_time_per_user) {
      delete parsedData?.subscriber_limit_count;
      delete parsedData?.subscriber_limit_time_period;
      delete parsedData?.subscriber_hour_interval;
    }
    if (!isTriggerMethod) {
      parsedData.trigger_method = '';
    }
    if (!isEntityField) {
      parsedData.entity_id = '';
    }

    parsedData.daily_trigger_limit_on = accountWideDailyTriggerLimitSwitch;
    parsedData.daily_trigger_total = accountWideDailyTriggerLimit
      ? Number(accountWideDailyTriggerLimit)
      : 0;
    parsedData.delete_contact_after_fire = deleteContactAfterFire;
    console.log(
      'DAILY TRIGGER TOTAL: ',
      accountWideDailyTriggerLimit,
      typeof accountWideDailyTriggerLimit,
    );

    console.log(
      '****** Del Contact After Fire: ',
      parsedData.delete_contact_after_fire,
    );
    saveTrigger(parsedData);

    // TODO: handle making campaign active if trigger is active
  };

  if (triggerId && !trigger) {
    return <Spinner fixed />;
  }

  const initialValues = {
    name: trigger?.name || '',
    description: trigger?.description || '',
    integration_id: trigger?.integration_id || '',
    trigger_method: trigger?.trigger_method || '',
    entity_id: trigger?.entity_id || '',
    status: trigger?.status === 1 ? true : false,
    campaign_id: trigger?.campaign_id || '',
    one_time_per_user: Boolean(trigger?.one_time_per_user),
    daily_spend_cap: Boolean(trigger?.daily_spend_cap),
    daily_spend_total: trigger?.daily_spend_total || 0.0,
    daily_trigger_limit_on: trigger?.daily_trigger_limit_on || 0,
    daily_trigger_total: trigger?.daily_trigger_limit,
    delete_contact_after_fire: trigger?.delete_contact_after_fire || 0,
    subscriber_hour_interval: trigger?.subscriber_hour_interval || 1,
    subscriber_limit_count: trigger?.subscriber_limit_count || 10,
    subscriber_limit_time_period:
      trigger?.subscriber_limit_time_period || 'day',
  };

  const formatDate = isoDate => {
    // Create a new Date object from the ISO string
    const date = new Date(isoDate);

    // Extract parts of the date
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');

    // Extract parts of the time
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Create the formatted date and time strings
    const formattedDate = `${month}-${day}-${year} at`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <Builder
      mode={mode}
      onClose={onClose}
      title={mode === 'dialog' ? '' : 'Create/Edit Trigger'}
      onSubmit={submitForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      cancelButtonProps={
        mode === 'dialog'
          ? { onClick: onClose }
          : {
              component: RouterLink,
              to: '/trigger-manager',
            }
      }
    >
      <DscListener />
      <FieldGroup
        title="Trigger Details"
        tooltipMessage="The Trigger is how your ESP will know when a contact is in their inbox. After naming select your integration for your ESP and then you can select the contact list or tag method. This means once they are in their inbox they will be tagged or added to a contact list in your ESP."
      >
        <Field name="name">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Name"
              placeholder="Enter Name"
              helperText={meta.touched && meta.error}
              error={meta.touched && Boolean(meta.error)}
            />
          )}
        </Field>
        <Field name="description">
          {({ field, meta }) => (
            <TextField
              {...field}
              multiline
              rows={4}
              label="Description"
              placeholder="Enter Description"
              helperText={meta.touched && meta.error}
              error={meta.touched && Boolean(meta.error)}
            />
          )}
        </Field>
        <Box m={3}>
          <Field name="status" label="Status" component={StatusSwitch} />
          {trigger?.status === 17 ? (
            <p className={classes.pausedStatusMsg}>
              Your trigger was temporarily paused by the system on{' '}
              {formatDate(trigger?.last_system_pause)}.
            </p>
          ) : (
            <></>
          )}
        </Box>

        {triggerLevelControls ? (
          <>
            <Divider sx={{ marginY: 3.5 }} />
            <Box m={3}>
              <Field
                name="one_time_per_user"
                label={
                  <Box display="flex" alignItems="center">
                    Set Limit Per Subscriber
                    <Tooltip
                      title="This option limits contacts to only go through the trigger the number of times you specify per specified time period."
                      arrow
                      classes={customToolTipClasses}
                    >
                      <span className={classes.tooltipIcon}>?</span>
                    </Tooltip>
                  </Box>
                }
                component={StatusSwitch}
              />
            </Box>
            {limitPerUSer !== true ? <Divider sx={{ marginY: 3.5 }} /> : null}
            {limitPerUSer === true && (
              <>
                <Box m={3}>
                  <Grid container spacing={3} sx={{ marginTop: -1 }}>
                    {limitPerUserFields.map(
                      ({ name, options, ...fieldConfig }) => (
                        <Grid key={name} item xs={12} sm={8}>
                          <Field name={name}>
                            {({ field, meta }) => (
                              <TextField
                                {...field}
                                {...fieldConfig}
                                inputProps={fieldConfig}
                                margin="none"
                                SelectProps={{ displayEmpty: true }}
                                placeholder={`Enter ${fieldConfig.label}`}
                                helperText={meta.touched && meta.error}
                                error={meta.touched && Boolean(meta.error)}
                              >
                                {options?.map(option => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </Field>
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Box>
                {partner.daily_trigger_limit_ability ? (
                  <Divider sx={{ marginY: 3.5 }} />
                ) : null}
              </>
            )}
            {dsc === true && (
              <Box m={3}>
                <Field
                  name="daily_spend_cap"
                  label={
                    <Box display="flex" alignItems="center">
                      Daily Spend Cap
                      <Tooltip
                        title="This sets a credit limit to the trigger so that only a certain dollar amount can be spent on this trigger."
                        arrow
                        classes={customToolTipClasses}
                      >
                        <span className={classes.tooltipIcon}>?</span>
                      </Tooltip>
                    </Box>
                  }
                  component={StatusSwitch}
                />
              </Box>
            )}
            {dsc === true && (
              <Box m={3} sx={{ width: 150 }}>
                <Field name="daily_spend_total">
                  {({ field, meta }) => (
                    <CurrencyTextField
                      {...field}
                      name="daily_spend_total"
                      variant="outlined"
                      currencySymbol="$"
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter="."
                    />
                  )}
                </Field>
              </Box>
            )}
          </>
        ) : null}
        {partner.daily_trigger_limit_ability ? (
          <div className={classes.m24}>
            <FieldGroup>
              <Box
                display="flex"
                alignItems="center"
                className={classes.headline}
              >
                Set Daily Trigger Limit
                <Tooltip
                  title="Set the max number of times per day this trigger will fire. (Note: Any number less than 0 will be set to 0.)"
                  arrow
                  classes={customToolTipClasses}
                >
                  <span className={classes.tooltipIcon}>?</span>
                </Tooltip>
              </Box>
              <StatusSwitch
                field={{
                  value: accountWideDailyTriggerLimitSwitch,
                  onChange: onChangeSwitchDailyTriggerLimit,
                }}
              />
              {accountWideDailyTriggerLimitSwitch ? (
                <FieldGroup>
                  <Box sx={{ width: 150, paddingTop: '36px' }}>
                    <TextField
                      className={classes.costText}
                      value={accountWideDailyTriggerLimit}
                      onChange={e => onAccountWideDailyTriggerLimitChange(e)}
                      name="daily_trigger_total"
                      margin="none"
                      type="number"
                    >
                      {accountWideDailyTriggerLimit}
                    </TextField>
                  </Box>
                </FieldGroup>
              ) : null}
            </FieldGroup>
          </div>
        ) : null}
      </FieldGroup>
      {partner.can_use_delete_contact_after_fire ? (
        <FieldGroup>
          <div className={classes.pl24}>
            <FieldGroup>
              <Box
                display="flex"
                alignItems="center"
                className={classes.headline}
              >
                Set Remove Contact from List
                <Tooltip
                  title="If turned on, contacts will be removed from a contact list after they have been delivered to."
                  arrow
                  classes={customToolTipClasses}
                >
                  <span className={classes.tooltipIcon}>?</span>
                </Tooltip>
              </Box>
              <StatusSwitch
                field={{
                  value: deleteContactAfterFire,
                  onChange: onChangeSwitchDeleteAfterFire,
                }}
              />
            </FieldGroup>
          </div>
        </FieldGroup>
      ) : (
        <></>
      )}

      <FieldGroup title="Integration Details">
        <Field name="integration_id" component={IntegrationDropdown} />
        <Field
          name="trigger_method"
          setIsTriggerMethod={setIsTriggerMethod}
          component={TriggerMethodDropdown}
        />
        <Field
          name="entity_id"
          setIsEntityField={setIsEntityField}
          component={EntityListDropdown}
        />
      </FieldGroup>
    </Builder>
  );
};

export default TriggerBuilder;
