import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import TriggerList from './TriggerList';
import PageHeader from '../../components/PageHeader';
import RouterLink from '../../components/RouterLink';
import Banner from '../../components/Banner';

const TriggerStats = () => {
  return (
    <>
      <Banner
        url="https://support.inboxmailers.com/how-to-create-a-trigger/"
        text="Here’s a quick walk-through video:"
      />
      <PageHeader
        title="Trigger Statistics"
        buttons={[
          <Button
            color="primary"
            to="/trigger-manager/add"
            variant="contained"
            component={RouterLink}
            startIcon={<AddIcon />}
          >
            Add new
          </Button>,
        ]}
      />
      <TriggerList />
    </>
  );
};

export default TriggerStats;
