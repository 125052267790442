import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { formatDate } from '../../utils/date';
import { API_BASE_URL } from '../../utils/apis/adminTemplates';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import axios from 'axios';
import { useStyles } from './styles';
import { LoadingButton } from '@material-ui/lab';

function ExportCreatives({ filterDate, filterFrom, filterTo, regChange }) {
  const partner = useCurrentPartner();
  const [logs, setLogs] = useState([]);
  const [downloadLogs, setDownloadLogs] = useState(false);
  const classes = useStyles();

  let apiUrl;
  if (filterDate === 'Custom') {
    apiUrl = `${API_BASE_URL}/partners/${partner.id}/logs/email-templates/custom/${filterFrom}/${filterTo}`;
  } else {
    apiUrl = `${API_BASE_URL}/partners/${partner.id}/logs/email-templates/${filterDate}`;
  }

  useEffect(() => {
    setDownloadLogs(false);

    // Define a function for fetching data and call it within the effect
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const dataArray = response.data;

        const dataArrayWithId = dataArray.map((item, index) => ({
          id: index + 1,
          ...item,

          creative_timestamp: formatDate(item.creative_timestamp),
          created_at: formatDate(item.created_at),
        }));

        setLogs(dataArrayWithId);
        setDownloadLogs(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, [regChange]); // An empty dependency array ensures that this effect runs only once

  return (
    <div>
      {logs.length === 0 ? (
        'No data to export'
      ) : (
        <CSVLink
          data={downloadLogs ? logs : '#'}
          filename="creative_action_logs"
          style={{ textDecoration: 'none', color: '#000' }}
          className={
            downloadLogs ? classes['clickable'] : classes['nonClickable']
          }
        >
          {downloadLogs ? 'Export' : 'Loading...'}
        </CSVLink>
      )}
    </div>
  );
}

export default ExportCreatives;
