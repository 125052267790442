import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import PageHeader from '../../components/PageHeader';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DataGrid from '../../components/DataGrid';
import RowActions from '../../components/RowActions';

import './styles.css';
import axios from 'axios';

const ReportsAll = () => {
  const partner = useCurrentPartner();
  const [work, setWork] = useState();
  const [loadMsg, setLoadMsg] = useState('Retreiving...');
  const thisURL = window.location.href.split('/');
  const thisReportsId = thisURL[thisURL.length - 1];

  const getPartner = () => {
    console.log('partner ID: ', partner);
    getData();
  };

  const getData = async () => {
    if (!partner.id) return;
    console.log('PARTNER ID: ', partner);
    await axios
      .get(`/reports/outgoingNotifications/csv?reportId=200&limit=all`, {})
      .then(response => {
        console.log('RESPONSE ID: ', response.data);

        console.log('CONDITION: ', partner.id !== response.data[0].partner_id);

        console.log('** WORK: ', response.data);
        setWork(response.data);
        setLoadMsg('');
      });
  };

  const [query, setQuery] = useState({ page: 1 });
  const columns = [
    {
      field: 'id',
      title: 'ID',
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'pixel_name',
      title: 'Pixel Name',
    },
    {
      field: 'pixel_status_owner',
      title: 'Pixel Status/Owner',
    },
    {
      field: 'from_date',
      title: 'From Date',
    },
    {
      field: 'to_date',
      title: 'To Date',
    },
    {
      field: 'created_at',
      title: 'Created At',
    },
    {
      field: 'view',
      title: 'View File',
    },
  ];

  const { isLoading, data: exportList } = useQuery([query], () => {}, {
    refetchInterval: 10000,
    keepPreviousData: true,
  });

  const formatedDate = str => {
    return str.slice(0, 10);
  };

  const setLink = id => {
    return <a href={`/reports/${id}`}>View</a>;
  };

  console.log('WORK : ', work);

  const dataRows = work
    ? work.map(row => {
        return {
          id: row.id,
          type: row.type,
          status: row.status,
          pixel_name: row.pixel_name ? row.pixel_name : '_',
          pixel_status_owner: row.pixel_status_owner
            ? row.pixel_status_owner
            : '-',
          from_date: row.from_date ? formatedDate(row.from_date) : '-',
          to_date: row.to_date ? formatedDate(row.to_date) : '-',
          created_at: row.created_at ? formatedDate(row.created_at) : '-',
          view: setLink(row.id),
        };
      })
    : [];

  useEffect(() => {
    getPartner();
  }, [partner]);

  return (
    <>
      <PageHeader title="Download Report" />
      <h3 id="load-message">{loadMsg}</h3>
      <h5 id="download-history-message">7 Day Download History</h5>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={dataRows}
        rowKey="exportListId"
        emptyText="No current Downloads available. Please check back."
        onQueryChange={setQuery}
      />
    </>
  );
};

export default ReportsAll;
