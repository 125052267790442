/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useState, useMemo, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
// mui
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Link from '@material-ui/core/Link';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
// import LockIcon from '@material-ui/icons/Lock';
// import DownloadIcon from '@material-ui/icons/Download';
// jQuery and datatables
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-bs4';
import DeleteIcon from '../../assets/icons/delete.svg';
import Download from '../../assets/icons/download.svg';
import Lock from '../../assets/icons/lock.svg';

// components
import DataGrid from '../../components/DataGrid';
import RouterLink from '../../components/RouterLink';
import CustomPopup from '../../components/CustomPopup';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import BuyCreditDialog from '../../components/UserProfileMenu/BuyCreditDialog';
import { useSnackbar } from 'notistack';
// css
import './style.css';
import './buttons.css';

const renderStatus = status => {
  switch (status) {
    case 'success':
      return 'Complete';
    case 'working':
      return 'Uploading';
    case 'fail':
      return 'Failed';
    case 'queuing':
      return 'Queued';
    default:
      return 'Pending';
  }
};

const renderStatusDetails = (status, message) => {
  let msg;
  try {
    msg = message ? JSON.parse(message) : null;
  } catch (error) {
    console.error('Failed to parse message:', error);
    msg = null;
  }
  switch (status) {
    case 'success':
      return 'Ready to download';
    case 'working':
      return '...';
    case 'fail':
      return msg?.message
        ? `Error: ${msg.message}`
        : 'Error: there was an issue with your list. Please contact support.';
    case 'queuing':
      return '...';
    default:
      return '...';
  }
};

const fetchData = async params => {
  const response = await axios.post(
    `/triggers/datatables?datatables=activity`,
    {
      ...params,
    },
  );
  return response;
};

const abbreviateNumber = value => {
  var newValue = value;
  if (value >= 1000 && value < 1000000) {
    newValue = (value / 1000).toFixed(1) + 'K';
  } else if (value >= 1000000 && value < 1000000000) {
    newValue = (value / 1000000).toFixed(1) + 'M';
  } else if (value >= 1000000000 && value < 1000000000000) {
    newValue = (value / 1000000000).toFixed(1) + 'B';
  } else if (value >= 1000000000000) {
    newValue = (value / 1000000000000).toFixed(1) + 'T';
  }
  return newValue.toString().replace('.0', '');
};

const getPercentOfTotal = (total, num) => {
  if (total === 0) {
    console.error('Cannot divide by zero');
    return 0;
  }
  let toReturn;
  const percent = (num / total) * 100;
  if (percent % 1 === 0) {
    toReturn = percent.toString();
  } else {
    toReturn = percent.toFixed(1);
  }

  return toReturn;
};

const formateUploadDate = uploadedDate => {
  const date = new Date(uploadedDate);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

const purchaseList = (fileName, listId, amountToDeduct, handleShowSuccess) => {
  // add authentication || vpc lockdown
  const source = axios.CancelToken.source();
  const promise = axios.get(
    `/activityDBSave?eventType=unlockActivity&fileName=${fileName}&listId=${listId}&amountToDeduct=${amountToDeduct}`,
    {
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    },
  );

  promise.cancel = () => {
    source.cancel(
      'Purchas could not be completed. Please try again, or contact support if issue persists.',
    );
  };

  handleShowSuccess(true);

  setTimeout(() => {
    window.location.reload();
  }, 1000);

  return promise;
};

const downloadFile = (url, name) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return;
};

const ListCollection = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
}) => {
  const partner = useCurrentPartner();
  const [isBuyCreditDialog, setIsBuyCreditDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [unlockModal, setUnlockModal] = useState(false);
  const [delPass, setDelPass] = useState(false);
  const [delFail, setDelFail] = useState(false);
  const [render, setRender] = useState(false);
  const [downloads, setDownloads] = useState(false);
  const tableRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: filePurchaseSuccess } = useMutation(() => {}, {
    onSuccess: () => {
      enqueueSnackbar('Purchase successful! File is being unlocked.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    },
    onError: error => {
      enqueueSnackbar('Unable to purchase file.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  const handleShowSuccess = bool => {
    setShowSuccess(bool);
    if (bool) filePurchaseSuccess(showSuccess);
  };

  const handleClose = () => {
    setDelPass(false);
    setDelFail(false);
  };

  const deleteList = id => {
    const source = axios.CancelToken.source();
    const promise = axios.get(
      `/activityDBSave?eventType=deleteActivity&activityId=${id}`,
      {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      },
    );

    promise.cancel = () => {
      source.cancel('Delete was cancelled due to error.');
    };

    return promise
      .then(response => {
        setDelPass(true);
      })
      .catch(error => {
        setDelFail(true);
        if (axios.isCancel(error)) {
          console.error('Request canceled:', error.message);
        } else {
          console.error('Delete failed:', error);
        }
        throw error;
      })
      .finally(() => {
        setRender(!render);
        setTimeout(() => {
          setDelPass(false);
          setDelFail(false);
        }, 4000);
      });
  };

  const columns = useMemo(
    () => [
      {
        title: 'Actions',
        data: null,
        width: '100px',
        orderable: false,
        render: function (data) {
          const deleteButton = `
            <a class="delete-button" style="cursor: pointer; padding: 5px;">
              <img src="${DeleteIcon}" alt="Delete" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
            </a>`;

          const actionButton = data.dwnld_url
            ? data.has_been_paid
              ? `
                <a class="download-button" style="cursor: pointer; padding: 5px;">
                  <img src="${Download}" alt="Download" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
                </a>`
              : `
                <a class="lock-button" style="cursor: pointer; padding: 5px;">
                  <img src="${Lock}" alt="Lock" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
                </a>`
            : '';

          return `
            <div style="display: flex; align-items: center;">
              ${deleteButton}${actionButton}
            </div>`;
        },
      },
      { title: 'Name', data: 'raw_name', width: '250px' },
      {
        title: 'Date Uploaded',
        data: null,
        width: '200px',
        render: function (data) {
          return formateUploadDate(data.created_at);
        },
      },
      {
        title: 'Status',
        data: null,
        width: '200px',
        render: function (data) {
          const statusObj = {
            fail: `<span style="color: rgb(224, 0, 1); font-weight: 600;">
                        ${renderStatus(data.status)}
                     </span>`,
            success: `<span style="color: #4bb543; font-weight: 600;">
                          ${renderStatus(data.status)}
                        </span>`,
            progress: `<span style="color: rgb(98, 98, 98); font-weight: 600; font-style: italic;">
                          ${renderStatus(data.status)}
                        </span>`,
            queued: `<span style="color: rgb(98, 98, 98); font-weight: 600; font-style: italic;">
                          ${renderStatus(data.status)}
                        </span>`,
            pending: `<span style="color: rgb(98, 98, 98); font-weight: 600; font-style: italic;">
                          ${renderStatus(data.status)}
                        </span>`,
            working: `<span style="color: rgb(98, 98, 98); font-weight: 600; font-style: italic;">
                          ${renderStatus(data.status)}
                        </span>`,
          };
          return statusObj[data.status];
        },
      },
      {
        title: 'Status Details',
        data: null,
        width: '400px',
        orderable: false,
        render: function (data) {
          return renderStatusDetails(data.status, data.refresh_json);
        },
      },
      {
        title: 'Total Emails',
        data: 'total_lines',
        width: '200px',
        className: 'text-center',
      },
      {
        title: 'Active Emails (%)',
        data: null,
        width: '200px',
        render: function (data) {
          return `${abbreviateNumber(data.active_lines)} (${getPercentOfTotal(
            data.total_lines,
            data.active_lines,
          )}%)`;
        },
      },
      {
        title: 'Active Last 30 Days (%)',
        data: null,
        width: '200px',
        render: function (data) {
          return `${abbreviateNumber(
            data['30day_active'],
          )} (${getPercentOfTotal(data.total_lines, data['30day_active'])}%)`;
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        columns: columns,
        dom: '<"d-flex align-items-center dt-controls"lf>rtip',
        responsive: true,
        scrollX: true,
        ajax: (data, callback, settings) => {
          fetchData(data)
            .then(result => {
              const recordsFiltered = result.recordsFiltered || 0;
              const recordsTotal = result.recordsTotal || 0;

              callback({
                draw: data.draw,
                recordsTotal: recordsTotal,
                recordsFiltered: recordsFiltered,
                data: result.data,
              });
            })
            .catch(error => {
              console.error('Error fetching data: ', error);
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            });
        },
        language: {
          infoFiltered: '',
        },
        initComplete: function () {
          const api = this.api();
          applyCustomStyles(api);
          attachEventListeners(api);
          // Custom styling and event listeners can be added here
        },
        drawCallback: function () {
          const api = this.api();
          applyCustomStyles(api);
          attachEventListeners(api);
          // Adjustments and additional event listeners can be added here
          api.columns.adjust();
        },
      });
    }

    function applyCustomStyles(api) {
      // Continue applying other custom styles
      $(api.table().container())
        .find('label, input, select, th, td, .dt-info')
        .css({
          'font-size': '14px',
          'font-weight': 'bold',
        });
      $(api.table().container()).find('label').css({
        padding: '5px',
      });
      $(api.table().container()).find('input, select').css({
        margin: '10px',
      });
      $(api.table().container()).find('thead tr th').css({
        'padding-left': '10px',
        'text-align': 'left',
        'border-bottom': '2px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
      });
      $(api.table().container()).find('tfoot tr th').css({
        'border-bottom': '2px solid #dee2e6',
      });
      $(api.table().container()).find('.dt-info').css({
        'padding-left': '10px',
      });
      $(api.table().container()).find('tbody tr td').css({
        'padding-left': '10px',
        'border-bottom': '1px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
        'white-space': 'normal', // Change to normal to wrap text
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
      });
      $(api.table().container())
        .find('tbody tr td:last-child, thead tr th:last-child')
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
        )
        .css({
          'border-right': '1px solid #dee2e6',
          'background-color': 'inherit',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
        )
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
        .css({
          'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
        });

      $(api.table().container()).find('ul.pagination').css({
        display: 'flex', // Use flexbox to align items horizontally
        justifyContent: 'center', // Center align pagination controls
        padding: '0', // Remove default padding
        marginTop: '10px', // Add some spacing above the pagination controls
        listStyle: 'none', // Remove default list styling
      });

      $(api.table().container()).find('ul.pagination li').css({
        display: 'inline',
        margin: '0 2px', // Add spacing between pagination items
      });

      $(api.table().container()).find('ul.pagination li a').css({
        display: 'inline-block', // Ensure links are inline
        padding: '5px 10px', // Add padding for clickable area
        border: '1px solid #ddd', // Border for pagination items
        borderRadius: '5px', // Rounded corners for pagination items
        textDecoration: 'none', // Remove underline from links
        color: '#333', // Text color
      });

      $(api.table().container()).find('ul.pagination li.active a').css({
        backgroundColor: '#E00001', // Highlight background color for the active page
        color: 'white', // Text color for active page
      });

      $(api.table().container()).find('ul.pagination li a:hover').css({
        backgroundColor: '#f1f1f1', // Background color on hover
        color: '#333', // Text color on hover
      });

      // Apply Flexbox styling to the parent container of "entries per page" and "Search"
      $(api.table().container()).find('.dt-controls').css({
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%', // Ensure it fills the entire width of the container
      });

      // Increase size for all inputs with ids matching dt-search-*
      $(api.table().container()).find('input[id^="dt-search-"]').css({
        height: '2.5rem',
        width: '15rem',
      });

      // Increase size for all selects with ids matching dt-length-*
      $(api.table().container()).find('select[id^="dt-length-"]').css({
        height: '2.5rem',
        width: '3rem',
      });

      // Apply flexbox to div.dt-length
      $(api.table().container()).find('div.dt-length').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('div.dt-search').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('.buttons-html5, .buttons-print').css({
        'background-color': '#E00001',
        'border-width': '2px',
        'border-radius': '10px',
        'border-color': '#E00001',
        color: 'white',
        padding: '10px',
        cursor: 'pointer', // Add this line to change the cursor on hover
      });
    }

    function attachEventListeners(api) {
      $('.delete-button')
        .off('click')
        .on('click', function () {
          const row = $(this).closest('tr');
          const rowData = api.row(row).data();

          setSelectedRow(rowData);
          setDelModal(true);
        });

      $('.lock-button')
        .off('click')
        .on('click', function () {
          const row = $(this).closest('tr');
          const rowData = api.row(row).data();

          setSelectedRow(rowData);
          setUnlockModal(true);
        });

      $('.download-button')
        .off('click')
        .on('click', function () {
          const row = $(this).closest('tr');
          const rowData = api.row(row).data();

          setSelectedRow(rowData);
          setDownloads(true);
        });
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [columns, render]);

  return (
    <>
      <Snackbar
        open={delPass}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#4caf50',
          }}
          message={<Typography>Successfully Deleted List</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      <Snackbar
        open={delFail}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#E00001',
          }}
          message={<Typography>Could not Delete List</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      {delModal ? (
        <CustomPopup
          buttonLabel="Delete"
          messageToDisplay={`Are you sure you want to delete "${selectedRow.raw_name}?" This action cannot be undone.`}
          entityLabel={`Delete ${selectedRow.raw_name}?`}
          queryKey="activityLists"
          queryFunction={() => deleteList(selectedRow.id)}
          open={delModal}
          setOpen={setDelModal}
        />
      ) : null}
      {unlockModal ? (
        <CustomPopup
          open={unlockModal}
          setOpen={setUnlockModal}
          buttonLabel="Purchase"
          entityLabel={
            partner.available_credits >= selectedRow.active_lines
              ? `Purchase List for ${selectedRow.active_lines} ${
                  selectedRow.active_lines === 1 ? 'Credit' : 'Credits'
                }`
              : `Looks like you need more Credits!`
          }
          messageToDisplay={
            partner.available_credits >= selectedRow.active_lines
              ? `Do you want to Purchase ${selectedRow.raw_name} for ${
                  selectedRow.active_lines
                } ${selectedRow.active_lines === 1 ? 'Credit' : 'Credits'}?`
              : `${selectedRow.raw_name} requires ${selectedRow.active_lines} ${
                  selectedRow.active_lines === 1 ? 'Credit' : 'Credits'
                } to purchase. Do you want to puchase more Credits?`
          }
          queryKey="purchaseList"
          queryFunction={() => {
            partner.available_credits >= selectedRow.active_lines
              ? purchaseList(
                  selectedRow.name,
                  selectedRow.id,
                  selectedRow.active_lines,
                  handleShowSuccess,
                )
              : setIsBuyCreditDialog(true);

            // query to determin if they have enough credist
            // insert row into transaction table; that insertion triggers the reduction of credits
            // if yes => ask if they're sure they want to buy it and reduce their credits to X, if yes  => reduce creds
            // if no => popup to display "buy more" -- you need X, but you have Y, you need Z more.
          }}
        />
      ) : null}
      {downloads ? (
        <CustomPopup
          queryKey="downloadList"
          entityLabel={`Your list is ready to download!`}
          messageToDisplay={`Do you want to download ${selectedRow.raw_name}?`}
          queryFunction={() => {
            downloadFile(selectedRow.dwnld_url, selectedRow.raw_name);
          }}
          open={downloads}
          setOpen={setDownloads}
        />
      ) : null}
      <div className="datatable-container">
        <table
          ref={tableRef}
          className="display"
          style={{ width: '100%' }}
        ></table>
      </div>
      {isBuyCreditDialog ? (
        <BuyCreditDialog onClose={() => setIsBuyCreditDialog(false)} />
      ) : null}
    </>
  );
};

export default ListCollection;

/*
import axios from 'axios';
import { useState, useMemo } from 'react';
import { useMutation } from 'react-query';
import Link from '@material-ui/core/Link';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import LockIcon from '@material-ui/icons/Lock';
import DownloadIcon from '@material-ui/icons/Download';
// components
import DataGrid from '../../components/DataGrid';
import RouterLink from '../../components/RouterLink';
import RowActions from '../../components/RowActions';
import CustomPopup from '../../components/CustomPopup';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import BuyCreditDialog from '../../components/UserProfileMenu/BuyCreditDialog';
import { useSnackbar } from 'notistack';
// css
import './style.css';
import './buttons.css';

const columns = [
  {
    field: 'actions',
    title: 'Actions',
    width: '100px',
  },
  {
    field: 'name',
    title: 'Name',
    width: '250px',
  },
  {
    field: 'dateUploaded',
    title: 'Date Uploaded',
    width: '200px',
    align: 'center',
  },
  {
    field: 'status',
    title: 'Status',
    width: '200px',
  },
  {
    field: 'details',
    title: 'Status Details',
    width: '400px',
  },
  {
    field: 'totalCount',
    title: 'Total Emails',
    width: '200px',
    align: 'center',
    tooltipMessage:
      'Total Count represents the total number of emails that were found in the uploaded file.',
  },
  {
    field: 'activeCount',
    title: 'Active Emails (%)',
    width: '200px',
    align: 'center',
    tooltipMessage:
      'Active Count represents the total number of emails that were active at the time the uploaded file was processed.',
  },
  // {
  //   field: 'active7',
  //   title: 'Active Last 7 Days (%)',
  //   width: '200px',
  //   align: 'center',
  //   tooltipMessage:
  //     'Represents the total number of emails that were active within the 7 days prior to the uploaded file being processed.',
  // },
  {
    field: 'active30',
    title: 'Active Last 30 Days (%)',
    width: '200px',
    align: 'center',
    tooltipMessage:
      'Represents the total number of emails that were active within the 30 days prior to the uploaded file being processed.',
  },
];

const renderStatus = status => {
  switch (status) {
    case 'success':
      return 'Complete';
    case 'working':
      return 'Uploading';
    case 'fail':
      return 'Failed';
    case 'queuing':
      return 'Queued';
    default:
      return 'Pending';
  }
};

const renderStatusDetails = (status, message) => {
  let msg;
  try {
    msg = message ? JSON.parse(message) : null;
  } catch (error) {
    console.error('Failed to parse message:', error);
    msg = null;
  }
  switch (status) {
    case 'success':
      return 'Ready to download';
    case 'working':
      return '...';
    case 'fail':
      return msg?.message
        ? `Error: ${msg.message}`
        : 'Error: there was an issue with your list. Please contact support.';
    case 'queuing':
      return '...';
    default:
      return '...';
  }
};

const downloadFile = (url, name) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return;
};

const purchaseList = (fileName, listId, amountToDeduct, handleShowSuccess) => {
  // add authentication || vpc lockdown
  const source = axios.CancelToken.source();
  const promise = axios.get(
    `/activityDBSave?eventType=unlockActivity&fileName=${fileName}&listId=${listId}&amountToDeduct=${amountToDeduct}`,
    {
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    },
  );

  promise.cancel = () => {
    source.cancel(
      'Purchas could not be completed. Please try again, or contact support if issue persists.',
    );
  };

  handleShowSuccess(true);

  setTimeout(() => {
    window.location.reload();
  }, 1000);

  return promise;
};

const deleteList = id => {
  const source = axios.CancelToken.source();
  const promise = axios.get(
    `/activityDBSave?eventType=deleteActivity&activityId=${id}`,
    {
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    },
  );

  promise.cancel = () => {
    source.cancel('Delete was cancelled due to error.');
  };

  return promise;
};

const abbreviateNumber = value => {
  var newValue = value;
  if (value >= 1000 && value < 1000000) {
    newValue = (value / 1000).toFixed(1) + 'K';
  } else if (value >= 1000000 && value < 1000000000) {
    newValue = (value / 1000000).toFixed(1) + 'M';
  } else if (value >= 1000000000 && value < 1000000000000) {
    newValue = (value / 1000000000).toFixed(1) + 'B';
  } else if (value >= 1000000000000) {
    newValue = (value / 1000000000000).toFixed(1) + 'T';
  }
  return newValue.toString().replace('.0', '');
};

const getPercentOfTotal = (total, num) => {
  if (total === 0) {
    console.error('Cannot divide by zero');
    return 0;
  }
  let toReturn;
  const percent = (num / total) * 100;
  if (percent % 1 === 0) {
    toReturn = percent.toString();
  } else {
    toReturn = percent.toFixed(1);
  }

  return toReturn;
};

const formateUploadDate = uploadedDate => {
  const date = new Date(uploadedDate);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

const ListCollection = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
}) => {
  const partner = useCurrentPartner();
  const [isBuyCreditDialog, setIsBuyCreditDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: filePurchaseSuccess } = useMutation(() => {}, {
    onSuccess: () => {
      enqueueSnackbar('Purchase successful! File is being unlocked.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    },
    onError: error => {
      enqueueSnackbar('Unable to purchase file.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  const handleShowSuccess = bool => {
    setShowSuccess(bool);
    if (bool) filePurchaseSuccess(showSuccess);
  };

  console.log('>>>>>>>> ', rows);

  const dataSource = useMemo(
    () =>
      rows.map(row => ({
        listId: row.id,
        createdAt: row.created_at,
        name: row.raw_name,
        description: row.description,
        trigger: row.trigger_id ? (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/trigger-manager/${row.trigger_id}/edit`}
          >
            {row.trigger_name}
          </Link>
        ) : (
          `-`
        ),
        totalCount: abbreviateNumber(row.total_lines) || 0,
        activeCount:
          `${abbreviateNumber(row.active_lines)} (${getPercentOfTotal(
            row.total_lines,
            row.active_lines,
          )}%)` || 0,
        // active7: `${row['7day_active']} (${getPercentOfTotal(row.total_lines, row['7day_active'])}%)`|| 0,
        active30:
          `${abbreviateNumber(row['30day_active'])} (${getPercentOfTotal(
            row.total_lines,
            row['30day_active'],
          )}%)` || 0,
        dateUploaded: (
          <span className={`row__dateUploaded--${row.created_at}`}>
            {formateUploadDate(row.created_at)}
          </span>
        ),
        status: (
          <span className={`row__status--${row.status}`}>
            {renderStatus(row.status)}
          </span>
        ),
        details: (
          <span className={`row__details--${row.status}`}>
            {renderStatusDetails(row.status, row.refresh_json)}
          </span>
        ),
        actions: (
          <div className="action-buttons">
            <RowActions
              actions={[
                {
                  title: 'Delete List',
                  class: `action-button`,
                  children: (
                    <CustomPopup
                      icon={<DeleteOutlinedIcon />}
                      buttonLabel="Delete"
                      messageToDisplay={`Are you sure you want to delete "${row.raw_name}?" This action cannot be undone.`}
                      entityLabel={`Delete ${row.raw_name}?`}
                      queryKey="activityLists"
                      queryFunction={() => deleteList(row.id)}
                    />
                  ),
                },
                row.dwnld_url
                  ? row.has_been_paid
                    ? {
                        title: 'Download List',
                        class: 'action-button',
                        children: (
                          <CustomPopup
                            icon={<DownloadIcon />}
                            queryKey="downloadList"
                            entityLabel={`Your list is ready to download!`}
                            messageToDisplay={`Do you want to download ${row.raw_name}?`}
                            queryFunction={() => {
                              downloadFile(row.dwnld_url, row.raw_name);
                            }}
                          />
                        ),
                      }
                    : {
                        title: 'Unlock List',
                        class: 'action-button',
                        children: (
                          <CustomPopup
                            icon={<LockIcon />}
                            buttonLabel="Purchase"
                            entityLabel={
                              partner.available_credits >= row.active_lines
                                ? `Purchase List for ${row.active_lines} ${
                                    row.active_lines === 1
                                      ? 'Credit'
                                      : 'Credits'
                                  }`
                                : `Looks like you need more Credits!`
                            }
                            messageToDisplay={
                              partner.available_credits >= row.active_lines
                                ? `Do you want to Purchase ${
                                    row.raw_name
                                  } for ${row.active_lines} ${
                                    row.active_lines === 1
                                      ? 'Credit'
                                      : 'Credits'
                                  }?`
                                : `${row.raw_name} requires ${
                                    row.active_lines
                                  } ${
                                    row.active_lines === 1
                                      ? 'Credit'
                                      : 'Credits'
                                  } to purchase. Do you want to puchase more Credits?`
                            }
                            queryKey="purchaseList"
                            queryFunction={() => {
                              partner.available_credits >= row.active_lines
                                ? purchaseList(
                                    row.name,
                                    row.id,
                                    row.active_lines,
                                    handleShowSuccess,
                                  )
                                : setIsBuyCreditDialog(true);

                              // query to determin if they have enough credist
                              // insert row into transaction table; that insertion triggers the reduction of credits
                              // if yes => ask if they're sure they want to buy it and reduce their credits to X, if yes  => reduce creds
                              // if no => popup to display "buy more" -- you need X, but you have Y, you need Z more.
                            }}
                          />
                        ),
                      }
                  : {
                      class: 'hide_button',
                    },
              ]}
            />
          </div>
        ),
      })),
    [rows],
  );

  return (
    <>
      <DataGrid
        pageable
        rowKey="listId"
        rows={dataSource}
        columns={columns}
        loading={isLoading}
        totalPages={totalPages}
        totalElements={totalElements}
        onQueryChange={onQueryChange}
        emptyText="You don't have any lists yet"
      />
      {isBuyCreditDialog ? (
        <BuyCreditDialog onClose={() => setIsBuyCreditDialog(false)} />
      ) : null}
    </>
  );
};

export default ListCollection;
*/
