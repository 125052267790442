/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
// Datatables
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import './buttons.css';
// MUI
import Box from '@material-ui/core/Box';
// Components
import Spinner from '../../components/Spinner';

const TriggerList = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
}) => {
  const tableRef = useRef(null);
  const [render, setRender] = useState(false);

  const columns = useMemo(
    () => [
      { title: 'Partner', data: 'partner_name', width: '250px' },
      { title: 'Name', data: 'name', width: '290px' },
      { title: 'Description', data: 'description', width: '300px' },
      { title: 'Integration', data: 'integration_name', width: '200px' },
      {
        title: 'Status',
        data: 'status',
        width: '135px',
        render: function (data) {
          if (data === 17) {
            return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(30, 146, 244); color: rgb(235 246 255); border: none;'>System Paused</span>`;
          } else if (data === 1) {
            return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(196, 248, 226); color: rgb(6, 165, 97); border: none;'>Active</span>`;
          } else if (data === 2) {
            return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(223, 223, 223); color: rgb(173, 173, 173); border: none;'>Deleted</span>`;
          } else {
            return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(255, 244, 201); color: rgb(249, 150, 0); border: none;'>Inactive</span>`;
          }
        },
      },
      {
        title: 'Subscriber Limit Count',
        data: 'subscriber_limit_count',
        render: function (data) {
          return data ? data : '-';
        },
      },
      {
        title: 'Delete Contact After Fire',
        data: 'delete_contact_after_fire',
        render: function (data) {
          return data === 1 ? 'ON' : 'OFF';
        },
      },
      {
        title: 'Trigger Limit Status',
        data: 'daily_trigger_limit_on',
        render: function (data) {
          return data === 1 ? 'ON' : 'OFF';
        },
      },
      { title: 'Daily Trigger Limit', data: 'daily_trigger_limit' },
      {
        title: 'One Time Per user',
        data: 'one_time_per_user',
        width: '100px',
        render: function (data) {
          return data ? 'True' : 'False';
        },
      },
    ],
    [],
  );

  const getParamsFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    params.draw = parseInt(urlParams.get('draw')) || 1;
    params.start = parseInt(urlParams.get('start')) || 0;
    params.length = parseInt(urlParams.get('length')) || 10;

    const order = urlParams.get('order');
    if (order) {
      params.order = JSON.parse(order);
    }

    const search = urlParams.get('search');
    if (search) {
      params.search = JSON.parse(search);
    }

    return params;
  };

  const fetchData = async params => {
    params.star = 1; // Ensure the 'star' param is included
    const urlParams = getParamsFromURL();

    const mergedParams = { ...urlParams, ...params };

    const { columns, ...paramsWithoutColumns } = mergedParams;
    const serializedParams = {};

    for (let key in paramsWithoutColumns) {
      if (paramsWithoutColumns.hasOwnProperty(key)) {
        if (typeof paramsWithoutColumns[key] === 'object') {
          serializedParams[key] = JSON.stringify(paramsWithoutColumns[key]);
        } else {
          serializedParams[key] = paramsWithoutColumns[key];
        }
      }
    }

    const queryString = new URLSearchParams(serializedParams).toString();
    const newUrl = `${window.location.pathname}?${queryString}`;
    window.history.replaceState(null, '', newUrl);

    const response = await axios.post(
      `/triggers/datatables?datatables=yes`,
      mergedParams,
    );
    return response;
  };

  useEffect(() => {
    const initialParams = getParamsFromURL();

    if (!initialParams.draw) {
      const initialDrawParams = {
        draw: 1,
        start: 0,
        length: 10,
        order: JSON.stringify([[0, 'asc']]),
        search: JSON.stringify({ value: '', regex: false }),
      };
      const queryString = new URLSearchParams(initialDrawParams).toString();
      const newUrl = `${window.location.pathname}?${queryString}`;
      window.history.replaceState(null, '', newUrl);
    }

    const initializeDataTable = () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      const table = $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        columns: columns,
        dom: '<"d-flex align-items-center dt-controls"lfB>rtip',
        responsive: true,
        scrollX: true,
        displayStart: initialParams.start || 0,
        pageLength: initialParams.length || 10,
        order: initialParams.order || [[0, 'asc']],
        search: initialParams.search || { value: '', regex: false },
        ajax: (data, callback, settings) => {
          fetchData(data)
            .then(result => {
              const recordsFiltered = result.recordsFiltered || 0;
              const recordsTotal = result.recordsTotal || 0;

              callback({
                draw: data.draw,
                recordsTotal: recordsTotal,
                recordsFiltered: recordsFiltered,
                data: result.data,
              });
            })
            .catch(error => {
              console.error('Error fetching data: ', error);
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            });
        },
        buttons: [
          {
            extend: 'csv',
            text: 'Export',
            action: function (e, dt, node, config) {
              fetchAllDataAndExport();
            },
          },
        ],
        language: {
          infoFiltered: '',
        },
        initComplete: function () {
          const api = this.api();
          applyCustomStyles(api);

          // Set search value from URL to the search input
          if (initialParams.search && initialParams.search.value) {
            api.search(initialParams.search.value).draw();
            $('input[type="search"]').val(initialParams.search.value);
          }

          // Bind the search input to the DataTable's search functionality
          const searchBox = $('input[type="search"]');
          searchBox.off('keyup').on('keyup', function () {
            api.search(this.value).draw();
          });
        },
        drawCallback: function (settings) {
          const api = this.api();
          applyCustomStyles(api);

          const draw = settings.iDraw || initialParams.draw;
          const start = settings._iDisplayStart;
          const length = settings._iDisplayLength;
          const order = api.order();
          const search = api.search();

          const queryString = new URLSearchParams({
            draw: draw,
            start: start,
            length: length,
            order: JSON.stringify(order),
            search: JSON.stringify({ value: search, regex: false }),
          }).toString();

          const newUrl = `${window.location.pathname}?${queryString}`;
          window.history.replaceState(null, '', newUrl);
        },
      });
    };

    initializeDataTable();

    window.addEventListener('resize', initializeDataTable);

    return () => {
      window.removeEventListener('resize', initializeDataTable);
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [columns, render]);

  async function fetchAllDataAndExport() {
    const table = $(tableRef.current).DataTable();
    const params = table.ajax.params();

    try {
      const response = await axios.post(
        '/triggers/datatables?datatables=yes&export=admin',
        params,
      );
      if (response.data && response.data.length > 0) {
        const csvData = convertToCSV(response.data);
        downloadCSV(csvData, 'export.csv');
        setRender(!render);
      }
    } catch (error) {
      console.error('Error fetching data for export:', error);
    }
  }

  function convertToCSV(data) {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map(header => {
        const val = row[header];
        return typeof val === 'string' ? `"${val.replace(/"/g, '""')}"` : val;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  function downloadCSV(csvData, filename) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function applyCustomStyles(api) {
    // Continue applying other custom styles
    $(api.table().container())
      .find('label, input, select, th, td, .dt-info')
      .css({
        'font-size': '14px',
        'font-weight': 'bold',
      });
    $(api.table().container()).find('label').css({
      padding: '5px',
    });
    $(api.table().container()).find('input, select').css({
      margin: '10px',
    });
    $(api.table().container()).find('thead tr th').css({
      'padding-left': '10px',
      'text-align': 'left',
      'border-bottom': '2px solid #dee2e6',
      'border-right': '1px solid #dee2e6',
    });
    $(api.table().container()).find('tfoot tr th').css({
      'border-bottom': '2px solid #dee2e6',
    });
    $(api.table().container()).find('.dt-info').css({
      'padding-left': '10px',
    });
    $(api.table().container()).find('tbody tr td').css({
      'padding-left': '10px',
      'border-bottom': '1px solid #dee2e6',
      'border-right': '1px solid #dee2e6',
      'white-space': 'normal', // Change to normal to wrap text
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    });
    $(api.table().container())
      .find('tbody tr td:last-child, thead tr th:last-child')
      .css({
        'border-right': 'none',
      });
    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
      )
      .css({
        'border-right': '1px solid #dee2e6',
        'background-color': 'inherit',
      });
    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
      )
      .css({
        'border-right': 'none',
      });
    $(api.table().container())
      .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
      .css({
        'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
      });

    $(api.table().container()).find('ul.pagination').css({
      display: 'flex', // Use flexbox to align items horizontally
      justifyContent: 'center', // Center align pagination controls
      padding: '0', // Remove default padding
      marginTop: '10px', // Add some spacing above the pagination controls
      listStyle: 'none', // Remove default list styling
    });

    $(api.table().container()).find('ul.pagination li').css({
      display: 'inline',
      margin: '0 2px', // Add spacing between pagination items
    });

    $(api.table().container()).find('ul.pagination li a').css({
      display: 'inline-block', // Ensure links are inline
      padding: '5px 10px', // Add padding for clickable area
      border: '1px solid #ddd', // Border for pagination items
      borderRadius: '5px', // Rounded corners for pagination items
      textDecoration: 'none', // Remove underline from links
      color: '#333', // Text color
    });

    $(api.table().container()).find('ul.pagination li.active a').css({
      backgroundColor: '#E00001', // Highlight background color for the active page
      color: 'white', // Text color for active page
    });

    $(api.table().container()).find('ul.pagination li a:hover').css({
      backgroundColor: '#f1f1f1', // Background color on hover
      color: '#333', // Text color on hover
    });

    // Apply Flexbox styling to the parent container of "entries per page" and "Search"
    $(api.table().container()).find('.dt-controls').css({
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%', // Ensure it fills the entire width of the container
    });

    // Increase size for all inputs with ids matching dt-search-*
    $(api.table().container()).find('input[id^="dt-search-"]').css({
      height: '2.5rem',
      width: '15rem',
    });

    // Increase size for all selects with ids matching dt-length-*
    $(api.table().container()).find('select[id^="dt-length-"]').css({
      height: '2.5rem',
      width: '3rem',
    });

    // Apply flexbox to div.dt-length
    $(api.table().container()).find('div.dt-length').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('div.dt-search').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('.buttons-html5, .buttons-print').css({
      'background-color': '#E00001',
      'border-width': '2px',
      'border-radius': '10px',
      'border-color': '#E00001',
      color: 'white',
      padding: '10px',
      cursor: 'pointer', // Add this line to change the cursor on hover
      'font-size': '1rem',
    });
  }

  return (
    <>
      <Box>
        <table
          ref={tableRef}
          className="display"
          style={{ width: '100%' }}
        ></table>
      </Box>
      {isLoading && (
        <Box display="flex" paddingTop={2} justifyContent="center">
          <Spinner />
        </Box>
      )}
    </>
  );
};

export default TriggerList;
