import { useCallback, useRef } from 'react';

export default function useEndlessScroll(isLoading, error, hasNext, callback) {
  const observer = useRef();

  const bottomPageObserver = useCallback(
    node => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !error && hasNext) {
          callback.onBottomReached();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, error, callback, hasNext],
  );

  return { bottomPageObserver };
}
