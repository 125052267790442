import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getEmailTemplate } from '../../utils/apis/emailTemplates';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Spinner from '../../components/Spinner';
import TemplateCard from '../../components/TemplateCard';
import axios from 'axios';
import { ArrowBack } from '@material-ui/icons';
import { useState } from 'react';
import useCurrentPartner from '../../hooks/useCurrentPartner';
import { API_BASE_URL } from '../../utils/apis/adminTemplates';

const AdminTemplates = () => {
  const [title, setTitle] = useState('');
  const queryClient = useQueryClient();
  const partner = useCurrentPartner();

  const {
    data,
    error,
    isLoading: fetchTemplateLoading,
  } = useQuery('fetchEmailTemp', async () => {
    const response = await axios.get(`${API_BASE_URL}/templates`);

    return response.data;
  });

  async function addDataToApi(newData) {
    const response = await axios.post(
      `${API_BASE_URL}/templates/${partner.id}/create`,
      newData,
    );
    return response.data;
  }
  const { mutate: createAdminTemplate, isLoading: createTemplateLoading } =
    useMutation(addDataToApi, {
      onMutate: newData => {
        queryClient.setQueryData('fetchEmailTemp', oldData => [
          ...oldData,
          newData,
        ]);

        return { newData };
      },
      onError: context => {
        queryClient.setQueryData('fetchEmailTemp', oldData =>
          oldData.filter(item => item !== context.newData),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries('fetchEmailTemp');
      },
    });

  const handleAddData = () => {
    const newData = {
      customTitle: title,
      template_id: data?.id,
    };
    createAdminTemplate(newData);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box>
        <Typography
          sx={{ fontWeight: 'bolder', marginBottom: '20px' }}
          variant="h4"
        >
          Admin only : Create custom template
        </Typography>
        <Card sx={{ width: '40%' }}>
          <Box sx={{ margin: '20px' }}>
            <Typography>Custom template title</Typography>
            <TextField
              sx={{ padding: '5' }}
              onChange={e => setTitle(e.target.value)}
              inputProps={{ maxLength: 12 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleAddData}
                disabled={title.length < 3 || createTemplateLoading}
              >
                {createTemplateLoading ? (
                  <CircularProgress
                    size="1.5rem"
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : (
                  'Submit'
                )}
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
      {fetchTemplateLoading && <Spinner />}
      <div
        style={{
          display: 'flex',
          gap: '20px',
          flexWrap: 'wrap',
          marginTop: 50,
        }}
      >
        {data &&
          data.map((item, index) => <TemplateCard data={item} key={index} />)}
      </div>
    </Box>
  );
};

export default AdminTemplates;
