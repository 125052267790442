import axios from 'axios';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useCallback, useMemo, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useStyles } from './styles';
import TriggerBuilder from '../../pages/TriggerBuilder';

function TriggerDropdown({ field, form }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    data: triggerList = [],
    isLoading: isTriggerListLoading,
    isFetching: isTriggerListFetching,
  } = useQuery('triggerList', () => axios.get('/triggers?pageSize=100'), {
    select: data =>
      data.content.map(trigger => ({
        value: trigger.id,
        label: trigger.name,
      })),
  });

  const isLoading = isTriggerListLoading || isTriggerListFetching;

  const emptyItem = useMemo(() => {
    if (isLoading) {
      return <MenuItem value="">Loading...</MenuItem>;
    }

    if (!field.value) {
      return <MenuItem value="">- No Trigger -</MenuItem>;
    }

    return null;
  }, [field.value, isLoading]);

  const handleChange = useCallback(
    e => {
      if (e.target.value === 'NEW') {
        setOpen(true);
        return;
      }

      field.onChange(e);
    },
    [field],
  );

  return (
    <>
      {open && (
        <TriggerBuilder
          mode="dialog"
          onClose={() => setOpen(false)}
          onSubmit={({ id }) => form.setFieldValue(field.name, id)}
        />
      )}
      <TextField
        {...field}
        select
        label="Trigger"
        value={isLoading ? '' : field.value}
        onChange={handleChange}
        SelectProps={{
          displayEmpty: true,
          disabled: isLoading,
          className: field.value ? classes.clearableSelect : '',
        }}
        InputProps={{
          endAdornment: field.value ? (
            <InputAdornment
              position="end"
              className={classes.clearSelectIcon}
              onClick={() => form.setFieldValue(field.name, '')}
            >
              <ClearIcon fontSize="small" color="action" />
            </InputAdornment>
          ) : null,
        }}
        helperText={form.touched[field.name] && form.errors[field.name]}
        error={form.touched[field.name] && Boolean(form.errors[field.name])}
      >
        <MenuItem value="NEW">
          <Typography
            color="primary"
            variant="subtitle2"
            className={classes.newOption}
          >
            <AddIcon
              fontSize="small"
              sx={{ marginRight: 1, marginLeft: -0.5 }}
            />
            Setup New Trigger
          </Typography>
        </MenuItem>

        {emptyItem}
        {triggerList.map((item, key) => (
          <MenuItem key={key} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

TriggerDropdown.propTypes = {
  meta: PropTypes.object,
  form: PropTypes.object,
};

export default TriggerDropdown;
