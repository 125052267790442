import qs from 'qs';
import axios from 'axios';
import { useQuery } from 'react-query';
import Box from '@material-ui/core/Box';
import { ReactTabulator } from 'react-tabulator';
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { formatDate } from '../../utils/date';
import Spinner from '../../components/Spinner';
import useEndlessScroll from '../../hooks/useEndlessScroll';
import useCurrentPartner from '../../hooks/useCurrentPartner';

const options = {
  headerSort: false,
  movableRows: false,
  movableColumns: true,
  downloadReady: (_, blob) => blob,
};

const OutgoingNotificationsTable = forwardRef(
  (
    {
      rowsPerLoad,
      dateFilter,
      outFromDate,
      outToDate,
      pixelOwnerFilter,
      emlHeader,
      emlField,
    },
    ref,
  ) => {
    const partner = useCurrentPartner();

    let columns = [
      { title: emlHeader, field: emlField, width: 300 },
      { title: 'Pixel ID', field: 'uuid', width: 375 },
      { title: 'Pixel Name', field: 'pixel_name', width: 200 },
      { title: 'List Name', field: 'list_name', width: 200 },
      { title: 'Integration', field: 'integration_name', width: 250 },
      { title: 'Status Code', field: 'status_code', width: 150 },
      { title: 'Response Text', field: 'response_text', width: 250 },
      { title: 'Date Triggered', field: 'date_sent', width: 250 },
    ];

    if (partner.id === 306) {
      console.log('ID: ', partner.id);
      columns = [
        { title: emlHeader, field: emlField, width: 300 },
        { title: 'Pixel ID', field: 'uuid', width: 375 },
        { title: 'Pixel Name', field: 'pixel_name', width: 200 },
        { title: 'Pixel Description', field: 'pixel_description', width: 250 },
        { title: 'List Name', field: 'list_name', width: 200 },
        { title: 'Integration', field: 'integration_name', width: 250 },
        { title: 'Response Text', field: 'response_text', width: 250 },
        { title: 'Status Code', field: 'status_code', width: 150 },
        { title: 'Date Triggered', field: 'date_sent', width: 250 },
      ];
    }

    const [activePage, setActivePage] = useState(0);
    const [hasNext, setHasNext] = useState(true);
    const dataTableRef = useRef(null);
    const query = {
      take: activePage * rowsPerLoad,
      offset: 0,
      filterDate: dateFilter,
      fDate: outFromDate,
      tDate: outToDate,
      filterPixelOwner: pixelOwnerFilter,
    };

    useImperativeHandle(ref, () => ({
      exportData() {
        dataTableRef.current.table.download('csv', 'network-triggers.csv');
      },
      resetData() {
        setActivePage(0);
        setHasNext(true);
      },
    }));

    const {
      refetch: fetchNotifications,
      isLoading = true,
      isFetching,
      error,
    } = useQuery(
      ['fetchOutgoingNotifications', query],
      () => axios.get(`/reports/outgoingNotifications?${qs.stringify(query)}`),
      {
        enabled: false,
        onSuccess: result => {
          if (result.length < rowsPerLoad) setHasNext(false);
          console.log('***** RESULT: ', result);
          dataTableRef.current?.table?.replaceData(
            result.map(row => {
              console.log('ROW: ', row);
              return {
                ...row,
                date_sent: row.date_sent.includes('EST')
                  ? row.date_sent
                  : formatDate(row.date_sent),
              };
            }),
          );
        },
      },
    );

    const { bottomPageObserver } = useEndlessScroll(
      isLoading || isFetching,
      error,
      hasNext,
      {
        onBottomReached: () => {
          setActivePage(prev => prev + 1);
        },
      },
    );

    useEffect(() => {
      if (activePage > 0) {
        fetchNotifications();
      } else {
        setActivePage(prev => prev + 1);
      }
    }, [activePage, fetchNotifications]);

    return (
      <>
        <ReactTabulator
          ref={dataTableRef}
          data={[]}
          columns={columns}
          tooltips
          resizableColumns
          resizableRows={false}
          options={options}
          layout="fitData"
          placeholder="No Triggers available yet."
        />
        {(isLoading || isFetching) && (
          <Box display="flex" paddingTop={2} justifyContent="center">
            <Spinner />
          </Box>
        )}
        <div ref={bottomPageObserver} />
      </>
    );
  },
);

export default OutgoingNotificationsTable;
