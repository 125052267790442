import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import ListIcon from '@material-ui/icons/List';
import SvgIcon from '@material-ui/core/SvgIcon';
import SupportIcon from '@material-ui/icons/Support';
import EmailIcon from '@material-ui/icons/Email';
import MarkEmailReadIcon from '@material-ui/icons/MarkEmailRead';
import { useState } from 'react';
import BoltOutlinedIcon from '@material-ui/icons/BoltOutlined';

import { useStyles } from './styles';
import ListItemLink from '../ListItemLink';
import { PixelIcon, ReportsIcon } from '../../assets/icons';
import { appPermissions, useAccess } from '../../hooks/useAccess';
import CampaignIcon from '@material-ui/icons/Campaign';
import DomainIcon from '@material-ui/icons/Domain';
import AdminPanelSettingsIcon from '@material-ui/icons/AdminPanelSettings';
import QueryStatsIcon from '@material-ui/icons/QueryStats';

import { Warning, Rowing } from '@material-ui/icons';

import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExtensionIcon from '@material-ui/icons/Extension';

const Navigation = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const classes = useStyles();
  const { hasPermission } = useAccess();
  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List disablePadding>
          <ListItemLink
            icon={<SvgIcon component={PixelIcon} />}
            primary="Pixel Management"
            to="/pixel"
          />
          {hasPermission(appPermissions.networkIn) && (
            <ListItemLink
              icon={<ListIcon />}
              primary="List Management"
              to="/list-manager"
            />
          )}
          <ListItemLink
            icon={<BoltOutlinedIcon />}
            primary="Trigger Management"
            to="/trigger-manager"
          />
          <ListItemLink
            icon={<SvgIcon component={ExtensionIcon} />}
            primary="Integrations"
            to="/integrations"
          />
          <ListItemLink
            icon={<SvgIcon component={ReportsIcon} />}
            primary="Reports"
            to="/reports"
          />
          <ListItemLink
            icon={<SvgIcon component={MarkEmailReadIcon} />}
            primary="Activity Checker"
            to="/activity-checker"
          />
          {/* <ListItemButton onClick={handleClick} sx={{ marginLeft: '-15px' }}>
            <ListItemLink
              icon={<SvgIcon component={EmailIcon} />}
              primary="Send Emails"
              to=""
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" sx={{ pl: 1 }} unmountOnExit>
            <ListItemLink
              icon={<SvgIcon component={EmailIcon} />}
              primary="Creatives"
              isBeta
              to="/emails"
            />
            <ListItemLink
              icon={<SvgIcon component={DomainIcon} />}
              primary="Domains"
              to="/domains"
            />
          </Collapse> */}

          {/* <ListItemLink
            icon={<SvgIcon component={CampaignIcon} />}
            primary="Campaigns"
            to="/campaigns"
          /> */}

          <li>
            <a
              href="https://support.inboxmailers.com/"
              rel="noreferrer"
              target="_blank"
              className={classes.offSiteLink}
            >
              <SupportIcon />
              <span className={classes.OffSiteLinkText}>Support</span>
            </a>
          </li>
          {hasPermission(appPermissions.adminRole) && (
            <ListItemLink
              icon={<SvgIcon component={Warning} />}
              primary="Admin Creatives"
              to="/admin-templates"
              isAdmin={true}
            />
          )}
          {hasPermission(appPermissions.adminRole) && (
            <ListItemLink
              icon={<SvgIcon component={Rowing} />}
              primary="Partner Activity"
              to="/partner-activity"
              isAdmin={true}
            />
          )}
          {(hasPermission(appPermissions.adminRole) ||
            hasPermission(appPermissions.triggerView)) && (
            <ListItemLink
              icon={<AdminPanelSettingsIcon />}
              primary="Admin Trigger View"
              to="/admin-trigger-view"
              isAdmin={true}
            />
          )}
          {(hasPermission(appPermissions.adminRole) ||
            hasPermission(appPermissions.triggerView)) && (
            <ListItemLink
              icon={<QueryStatsIcon />}
              primary="Trigger Stats"
              to="/trigger-stats"
              isAdmin={true}
            />
          )}
        </List>
      </div>
    </Drawer>
  );
};

export default Navigation;
